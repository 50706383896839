@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 100;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Thin.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 200;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-ExtraLight.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Light.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Medium.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-SemiBold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Bold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 800;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-ExtraBold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Black.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Light.woff2')
    format('woff2');
  // HINT: 국문, 일문, 중문만 Noto Sans KR 적용. 나머지는 Roboto
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Regular.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Medium.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Bold.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}

body.grabbing * {
  cursor: grabbing !important;
  user-select: none;
}

.material-icons {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: top;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  &.md-10 {
    font-size: 10px;
  }
  &.md-12 {
    font-size: 12px;
  }
  &.md-14 {
    font-size: 14px;
  }
  &.md-16 {
    font-size: 16px;
  }
  &.md-18 {
    font-size: 18px;
  }
  &.md-20 {
    font-size: 20px;
  }
  &.md-24 {
    font-size: 24px;
  }
  &.md-36 {
    font-size: 36px;
  }
  &.md-48 {
    font-size: 48px;
  }
  &.md-96 {
    font-size: 96px;
  }
  &.white {
    color: #fff;
  }
  &.black {
    color: #222;
  }
  &.primary {
    color: @primary-color;
  }
  &.gray {
    color: #7e7e7e !important;
  }
  &.cursor {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    color: #a9a6a6;
  }
}
.material-icons.outlined {
  font-family: 'Material Icons Outlined' !important;
}

* {
  box-sizing: inherit;
  font-family: 'Pretendard', 'Noto Sans KR', 'Roboto', sans-serif !important;
  font-feature-settings: normal;
  font-variant-numeric: normal;
}

/*
 * customize ant design 
 */
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}
.ant-pagination {
  text-align: center;
}
.ant-table-pagination-right {
  justify-content: center;
}
.ant-pagination {
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid #e7e7e7;

    a {
      font-weight: 500;
    }
  }

  .ant-pagination-item-active {
    border-color: @primary-color;
  }
  .ant-pagination-prev:hover,
  .ant-pagination-next:hover,
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    border-color: @primary-color;
    background-color: #fff;

    .ant-pagination-item-link {
      background-color: #fff;
    }
    a {
      color: @primary-color;
    }
  }

  .ant-pagination-disabled:hover {
    border-color: #e7e7e7;
  }
}

.ant-pagination .ant-pagination-options {
  display: none;
}
.ant-table-wrapper {
  overflow: hidden;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  justify-content: center;
}
.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #e7e7e7;
}
.ant-pagination-item-link:hover {
  path {
    fill: @primary-color;
  }
}
.ant-pagination-disabled {
  .ant-pagination-item-link,
  .ant-pagination-item-link:hover {
    path {
      fill: #d3d3d3;
    }
  }
}
.ant-form-item {
  line-height: 1.5715;
}
.ant-form-item-label {
  overflow: visible;
}

.ant-menu-submenu-popup {
  position: fixed;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-statistic-content-value {
  font-size: 18px;
}
.ant-form-item-explain.ant-form-item-explain-success {
  color: @primary-color;
}

.ant-menu-submenu-popup > .ant-menu {
  border-radius: 4px;
}
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item {
  width: 100%;
  margin-inline: 0;
}

// Message
.ant-message {
  top: 20% !important;
}

.ant-message-notice-content {
  border-radius: 4px !important;
  min-height: 56px;
  padding: 16px 20px 12px 20px !important;
}
.ant-message-custom-content {
  display: flex;
  white-space: pre-line;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}

.ant-message .ant-message-notice-wrapper {
  .ant-message-custom-content > .anticon {
    font-size: 20px;
  }

  .ant-message-warning > .anticon {
    color: #fad716;
  }
  .ant-message-info > .anticon {
    color: #1890ff;
  }
  .ant-message-success > .anticon {
    color: #29bf5f;
  }
  .ant-message-error > .anticon {
    color: #ff4d4f;
  }
}
.ant-notification-notice-description {
  white-space: pre-line;
}
.ant-statistic .ant-statistic-content-value,
.ant-statistic .ant-statistic-content {
  font-size: 18px;
}
.ant-page-header {
  padding-right: 0;
}
.ant-page-header-back {
  margin-right: 14px;
}
.ant-page-header .ant-page-header-heading-sub-title {
  font-size: 18px;
  color: @primary-color;
}
.ant-page-header-heading-left {
  overflow: visible;
  flex-wrap: nowrap;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}
.ant-pagination-options {
  display: none;
}
.ant-modal .ant-modal-header {
  padding: 16px 24px;
  height: min-content;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
}
.ant-modal-title {
  min-height: 16px;
}
.ant-modal-wrap {
  z-index: 1010;
}
.ant-modal .ant-modal-content {
  border: 1px solid #3785f7;
  padding: 0;
  border-radius: 4px;
}
.ant-modal-confirm.ant-modal .ant-modal-body,
.ant-modal .ant-modal-body {
  padding: 24px 40px 32px;
}
.ant-modal-confirm {
  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-confirm-btns {
    float: none;
    text-align: center;

    .ant-btn {
      font-size: 12px;
      padding: 8px 22px;
      line-height: 18px;
      height: auto;
    }
  }
}

.ant-modal .ant-modal-close {
  width: 56px;
  height: 56px;
  top: 0;
  right: 0;
  inset-inline-end: 0;

  &:hover {
    background-color: transparent;
  }
}

.ant-modal .ant-modal-footer {
  padding: 10px 16px;
  margin-top: 0;
  border-top: 1px solid #f0f0f0;
}

.ant-page-header-heading-title {
  font-size: 24px;
  line-height: 34px;
  flex-shrink: 0;
}
.ant-badge-dot {
  width: 6px;
  min-width: 6px;
  height: 6px;
  background-color: #3785f7;
  border-radius: 50%;
}
.ant-radio-button-wrapper-checked.ant-radio-button-wrapper:first-child {
  border-right-color: @primary-color !important;
}
.ant-radio-group-outline {
  display: flex;
}
.ant-radio-button-wrapper {
  height: 44px;
  line-height: 44px;
  flex: 1 1 100%;
  text-align: center;
  padding: 0 8px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.ant-radio-disabled + span {
  color: #000;
}

.ant-card-bordered {
  border-color: #d3d3d3;
}
.ant-btn::after {
  display: none;
}

.ant-btn-primary {
  box-shadow: none;
  background: #3785f7;
  border-color: #3785f7;

  &:focus {
    background: #3785f7;
    border-color: #3785f7;
  }
  &:hover,
  &:active {
    background: #1467e2;
    border-color: #1467e2;
  }
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background: #1467e2;
  border-color: #1467e2;
}
/* form */
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: @primary-color;
  font-size: 18px;
  margin-inline-end: 0;
}
.ant-form-item {
  margin-bottom: 16px;
}
.ant-form-item .ant-form-item-label {
  overflow: visible;
}
.ant-form-item .ant-form-item-label > label {
  height: 44px;
  font-size: 18px;
  color: #222;
}

.ant-form-item .ant-form-item-explain {
  font-size: 10px;
  color: @primary-color;
}

/* input */
.ant-input-outlined {
  border: 1px solid #d3d3d3;
}

.ant-input,
.ant-input-number,
.ant-select-multiple .ant-select-selector {
  border-radius: 4px;
}

.ant-input-number .ant-input-number-handler-wrap {
  border-radius: 2px;
}

.ant-input-number .ant-input-number-handler {
  border-inline-start: none;

  &:hover {
    background-color: #f1f6ff;
  }
}

.ant-input-number .ant-input-number-input {
  padding-left: 16px;
}

.ant-input-number-outlined .ant-input-number-handler-wrap .ant-input-number-handler {
  flex: none;
}
.ant-input-number-outlined .ant-input-number-handler-wrap .ant-input-number-handler-down {
  border-block-start: none;
}

.ant-input::placeholder {
  color: #a9a6a6;
}

input.ant-input:not(.ant-transfer-list-search),
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number-input,
.ant-picker {
  height: 44px;
  padding-left: 16px;

  &::placeholder {
    color: #a9a6a6;
  }
  &.ant-input-sm {
    height: 32px;
  }
}

.ant-input-affix-wrapper {
  height: 44px;
  padding-left: 0;
}

.ant-input-number-input {
  height: 42px;
}
input.ant-input-number-input {
  font-size: 14px;
}
.ant-input:focus,
.ant-input-number:focus,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}

/* select */
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d3d3d3;
}
.ant-select .ant-select-selection-placeholder {
  color: #a9a6a6;
}
.ant-select-dropdown .ant-select-item {
  padding: 8px 16px;
  min-height: 36px;
  line-height: 20px;
  background-color: #fff;
  color: #222;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px;
  padding-left: 16px;

  .ant-select-selection-item {
    line-height: 34px;
  }
}

.ant-select-multiple .ant-select-selector {
  min-height: 44px;
  padding: 4px 52px 4px 16px;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
  background: #e7e7e7;
  color: #4e5968;
  font-size: 12px;
  font-weight: 600;
}

.ant-select-single .ant-select-selector {
  border-radius: 4px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search,
.ant-select-multiple .ant-select-selection-placeholder {
  inset-inline-start: 16px;
}
.ant-select-selection-placeholder {
  color: #a9a6a6;
}
.ant-select-multiple .ant-select-selection-search {
  margin-left: 0;
}
input.ant-input[disabled],
.ant-picker-input > input[disabled],
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator,
.ant-picker.ant-picker-disabled .ant-picker-suffix,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number-disabled,
.ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:hover,
textarea.ant-input[disabled] {
  color: #a9a6a6;
  &.ant-radio-button-wrapper-checked {
    background-color: #d5d5d5;
    color: #fff;
  }
}

.ant-select-dropdown {
  padding: 4px 0;
}

.ant-select-item-option-content {
  white-space: pre-wrap !important;
}

.ant-form-item-control-input {
  min-height: 44px;
}

.ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 42px;
}
.ant-radio-wrapper {
  font-size: 16px !important;
  margin-inline-end: 0;
  span {
    width: max-content;
  }
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background: #fff;

  &::after {
    background: #3785f7;
    width: 20px;
    height: 20px;
    margin-block-start: -10px;
    margin-inline-start: -10px;
  }
}
.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
  background-color: #f6f6f6;
}
.ant-input-affix-wrapper > input.ant-input {
  height: auto;
}
.ant-input-number.hide-handler > .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  width: 18px;
}
.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-group > .ant-input:first-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-input-group-wrapper-outlined .ant-input-group-addon {
  width: 50px;
  border: none;
  font-size: 18px;
  line-height: 1.33;
  background-color: transparent;
}
.ant-input-group-wrapper-outlined.ant-input-group-wrapper-disabled .ant-input-group-addon,
.ant-input-group-wrapper-outlined.ant-input-group-wrapper-disabled
  .ant-input-group-addon:hover:not([disabled]) {
  background-color: transparent;
  color: inherit;
}

.ant-input-textarea {
  &.ant-input-textarea-show-count {
    font-size: 10px;
  }

  textarea {
    padding: 12px 16px !important;
  }
}
.ant-select-single,
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 44px;
}
.ant-select- .ant-select-arrow {
  color: #a9a6a6;
}
.ant-checkbox .ant-checkbox-inner {
  border-radius: 2px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @primary-color;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e7e7e7;
  border: 1px solid #d3d3d3;

  &::after {
    border-color: #a2a2a2;
  }
}
.ant-checkbox-disabled + span {
  color: #000;
}

.ant-checkbox-inner {
  border-radius: 2px;
}
.ant-btn {
  height: 44px;
  border-radius: 4px;
  border-color: @primary-color;
  box-shadow: none;
  gap: 4px;

  &.ant-btn-sm {
    height: 32px;
  }

  &[disabled],
  &[disabled]:hover {
    background: #eff1f8;
    border-color: #fff;
    color: #9195a5;
    opacity: 1;
    text-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.ant-btn-link {
    border-color: transparent;
    height: 24px;
  }

  &.ant-btn-gray {
    color: #7e7e7e;
    border-color: #d3d3d3;
  }
  &.ant-btn-waiting {
    border: none;
    background-color: #eff1f8;
    color: #9195a5;
    font-weight: 500;
    cursor: default;

    &:hover {
      background-color: #eff1f8;
      color: #9195a5;
    }

    &[disabled],
    &[disabled]:hover {
      opacity: 1;
    }
  }
  i.material-icons {
    margin-right: 4px;
    color: @primary-color;
    line-height: 22px;
  }
  i,
  span {
    vertical-align: middle;
  }
}
.ant-btn-icon.ant-btn-loading-icon {
  color: @primary-color;
  font-size: 18px;
}
.ant-btn-primary {
  .ant-btn-icon.ant-btn-loading-icon {
    color: #fff;
  }
}

a.ant-btn {
  padding-top: 4px;
  line-height: 32px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: relative;
  top: 2px;
  left: -1px;
  color: @primary-color;
  font-size: 18px;
  margin-right: 0;
}
.ant-form-item-explain {
  position: relative;
  font-size: 10px;
  min-height: 16px;
}

/* table */
.ant-table-wrapper {
  &.horizontal .ant-table {
    th {
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      padding: 12px 20px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
    }

    td {
      padding: 12px 20px !important;
      font-size: 16px;
      color: #222;
    }
  }
  .ant-table {
    overflow: hidden;
  }
  .ant-table-thead > tr > th,
  .ant-table-small tr > th {
    background-color: #f5f9ff;
  }

  .ant-table-small .ant-table-thead > tr > th {
    background-color: #f5f9ff !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td,
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: #dcf3ff;
  }

  .ant-table-thead > tr > th {
    padding: 10px 4px;
    font-weight: 400;
  }

  .ant-table-tbody > tr > td {
    padding: 10px 4px;
    line-height: 20px;
    font-weight: 400;

    &.ant-table-selection-column {
      padding: 12px;
    }
  }

  .ant-table-tbody > tr > td {
    color: #7e7e7e;
  }

  .ant-table-pagination.ant-pagination {
    margin-top: 24px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0;
  }

  .ant-table-footer {
    padding: unset;
    color: unset;
    background-color: unset;
    border-radius: unset;
  }
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  display: none;
}

.ant-checkbox-inner::after {
  display: block !important;
}

/* statistics */
.ant-statistic {
  .ant-statistic-title {
    text-align: center;
    color: #283c54;
    opacity: 0.5;
    font-size: 12px;
  }
  .ant-statistic-content {
    text-align: center;

    .ant-statistic-content-value {
      font-size: 16px;
      font-weight: 500;
      color: #4e5968;
      line-height: 1.5;
      word-break: break-all;
    }
  }
}

/* collapse */
.ant-collapse-header {
  border-radius: 8px;
}

// 담당자, 바이어 목록
.ant-table-wrapper.secondary {
  table {
    border-spacing: 0 8px;
    thead.ant-table-thead {
      th {
        padding-top: 8px;
        padding-bottom: 8px;
        border-top: 1px solid #d7e7fe;
        border-bottom: 1px solid #d7e7fe;
      }
    }
    tbody.ant-table-tbody {
      tr {
        &.ant-table-row-selected > td {
          background-color: #dcf3ff;
        }
        td {
          border: none;
          background-color: #f6f7f9;
          color: #4e5968;

          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}

.ant-table-wrapper.tertiary {
  & > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    border-radius: 0;

    & > .ant-table-container > .ant-table-content > table {
      & > thead > tr > th.ant-table-cell {
        border-top: 2px solid #3785f7;
        height: 46px;
        text-align: center;
        background-color: #f9f9f9;
        color: #4e5968;
        font-weight: 500;
      }

      & > tbody > tr > td.ant-table-cell {
        font-weight: 500;
        color: #4e5968;
      }
    }
  }
}

// descriptions
.ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view .ant-descriptions-row,
.ant-descriptions.ant-descriptions-bordered
  > .ant-descriptions-view
  .ant-descriptions-row
  > .ant-descriptions-item-label,
.ant-descriptions.ant-descriptions-bordered
  > .ant-descriptions-view
  .ant-descriptions-row
  > .ant-descriptions-item-content {
  border-color: #d8d8d8;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-row,
.ant-descriptions-bordered .ant-descriptions-view {
  border-color: #d8d8d8;
}

.ant-descriptions.ant-descriptions-bordered
  > .ant-descriptions-view
  .ant-descriptions-row
  > .ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
}

/*
 * customize react-pdf
 */
.react-pdf__Document {
  width: 100%;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__textContent {
  user-select: none;
}

/*
 * customize react-pdf-reader
 */
._1CDf-Fj0nLQTDw84Sho5JS {
  height: auto !important;
}
._1CDf-Fj0nLQTDw84Sho5JS > canvas {
  width: 100%;
}
.react-pdf__Page__textContent {
  display: none;
}

/*
 * customize suneditor-react 
 */
.sun-editor {
  border: none !important;
}
.se-wrapper-inner {
  padding: 0 !important;
}
.se-resizing-bar {
  display: none !important;
}

.ant-overlay-position {
  z-index: 1;
  .ant-dropdown-arrow {
    top: -18px;
  }

  .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
    transform: translateY(-24px);
  }
}

.ant-form-item-has-error {
  .custom-range-picker,
  .custom-range-picker:hover,
  .custom-range-picker:focus-within,
  .custom-date-picker,
  .custom-date-picker:hover,
  .custom-date-picker:focus-within {
    transition: all 0.3s ease;
    border: 1px solid #ff4d4f;
  }

  .custom-range-picker:focus-within,
  .custom-date-picker:focus-within {
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
}

.ant-empty-description {
  color: rgba(34, 34, 34, 0.25) !important;
}

.ant-upload-wrapper .ant-upload-drag {
  background: none;
  border: none;
}

.ant-upload-drag-container {
  .ant-btn {
    display: inline-block;
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: #727272;

    .material-icons {
      margin-right: 0;
      color: #a2a2a2;
    }
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.15);
    color: #727272;
  }

  .ant-upload p {
    padding-top: 16px;
    font-size: 10px;
    color: #7e7e7e;
  }
}

.ant-progress .ant-progress-inner {
  background-color: #f5f5f5;
}

.ant-switch,
.ant-switch:hover:not(.ant-switch-disabled) {
  background-color: #e7e7e7;
}

.ant-input-textarea-show-count .ant-input-data-count {
  bottom: -16px;
  font-size: 10px;
}

.ant-form-item .ant-form-item-label > label::after {
  margin: 0;
}

textarea.ant-input {
  resize: none;
}

@primary-color: #3785f7;@error-color: #ff4d4f;@link-color: #3785f7;@disabled-color: #e7e7e7;@border-radius-base: 4px;@screen-xs: 360px;@screen-sm: 600px;